import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import StyledHero from '../components/StyledHero'
import SEO from "../components/SEO"

const Services = ({data}) => {
  return (
    <Layout>
      <SEO title="Services" />
      <StyledHero img={data.servicesBg.childImageSharp.fluid}/>
    </Layout>
  )
}

export const query = graphql`
    query {
        servicesBg:file(relativePath:{eq:"servicesBg.jpg"}) {
            childImageSharp {
                fluid(quality:90, maxWidth:4160) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default Services

